import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from '@angular/fire/auth';
import { UserDB } from '../interfaces/interfaces';
import { DataService } from './data.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: UserDB

  constructor(public auth: Auth) {
    this.authStatusListener();
  }

  currentUser = null
  private authStatusSub = new BehaviorSubject(this.currentUser);
  currentAuthStatus = this.authStatusSub.asObservable();

  async register({ email, password }) {
    try {
      const user = await createUserWithEmailAndPassword(this.auth, email, password)
      return user
    } catch (e) {
      /* console.error(e) */
      return null
    }
  }

  async login({ email, password }) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password)
      return user
    } catch (e) {
      /* console.error(e) */
      return null
    }
  }

  async resetPassword(email) {
    try {
      await sendPasswordResetEmail(this.auth, email)
      return true
    } catch (error) {
      /* console.error(error) */
      return false
    }
  }

  logout() {
    return signOut(this.auth)
  }

  getUserId() {
    return this.auth.currentUser
  }

  isLogged() {
    return this.auth.currentUser != null ? true : false
  }

  getAuth() {
    return this.auth
  }

  authStatusListener() {
    this.auth.onAuthStateChanged((credential) => {
      if (credential) {
        /* console.log(credential); */
        this.authStatusSub.next(credential);
        /* console.log('User is logged in'); */
      }
      else {
        this.authStatusSub.next(null);
        /* console.log('User is logged out'); */
      }
    })
  }

  /* async updateUserAuth(displayName) {
    return updateProfile(this.auth.currentUser, { displayName }).then(() => {
      return true
    })
  } */

}