import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserDB } from './interfaces/interfaces';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  darkMode: boolean = true
  user: UserDB = null

  isAuthenticated = null

  public appPages = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Perfil', url: '/perfil', icon: 'person' },
    { title: 'Hermanos', url: '/users', icon: 'people' },
    { title: 'Programas', url: '/reunions', icon: 'list' },
    { title: 'Canciones', url: '/songs', icon: 'musical-notes' },
    { title: 'Acordes', url: '/chords', icon: 'musical-note' },
    { title: 'Finanzas', url: '/finance', icon: 'cash' },
    /* { title: 'Iniciar Sesión', url: '/login', icon: 'cash' }, */
    /*     { title: 'Cerrar Sesión', url: '/folder/Logout', icon: 'log-out' }, */
  ];
  /*   public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders']; */
  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {
    this.authService.authStatusListener()
    this.authService.currentAuthStatus.subscribe(authStatus => {
      this.isAuthenticated = authStatus
    })

    this.authService.currentAuthStatus.subscribe(() => {
      if (this.authService.getUserId()) {
        this.dataService.getUserbyEmail(this.authService.getUserId().email).subscribe(u => {
          this.user = u[0]
        })
      }
    })
    /* this.getUserData() */
    this.checkDarkMode()
  }

  async getUserData() {
    const loading = await this.loadingCtrl.create()

    loading.present()

    setTimeout(() => {

      loading.dismiss()
    }, 1000);
  }

  async logout() {
    await this.authService.logout()
    this.router.navigateByUrl('/', { replaceUrl: true }).then(() => window.location.reload())
  }

  async toLogin() {
    /* await this.authService.logout() */
    this.router.navigateByUrl('/login', { replaceUrl: true })/* .then(() => window.location.reload()) */
  }

  checkDarkMode() {
    if (localStorage.getItem('data-theme')) {
      if (localStorage.getItem('data-theme') == 'dark') {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        this.darkMode = false
        document.body.setAttribute('data-theme', 'light');
      }
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      if (prefersDark.matches) {
        document.body.setAttribute('data-theme', 'dark');
      } else {
        document.body.setAttribute('data-theme', 'light');
      }
    }
  }

  change(event) {
    this.darkMode = !this.darkMode
    if (this.darkMode) {
      document.body.setAttribute('data-theme', 'dark');
      localStorage.setItem('data-theme', 'dark')
    } else {
      document.body.setAttribute('data-theme', 'light');
      localStorage.setItem('data-theme', 'light')
    }
  }

}
