// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'ibmapp-22270',
    appId: '1:763401725402:web:7ae6973d388f3601b7cce3',
    databaseURL: 'https://ibmapp-22270-default-rtdb.firebaseio.com',
    storageBucket: 'ibmapp-22270.appspot.com',
    locationId: 'southamerica-east1',
    apiKey: 'AIzaSyAZHF34pEpVfQXNgjn7mT07MX310VG5MJA',
    authDomain: 'ibmapp-22270.firebaseapp.com',
    messagingSenderId: '763401725402',
  },
  production: false
};

export const ENCRYPTPASS = 'hola1234'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
