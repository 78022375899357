import { Injectable } from '@angular/core';
import { addDoc, collectionData, deleteDoc, doc, docData, Firestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { Storage, ref, uploadString, getDownloadURL, UploadTask, uploadBytes } from '@angular/fire/storage';
import { collection, limit, query, orderBy, where, getDocs } from '@firebase/firestore';
import { Observable } from 'rxjs';
import { ENCRYPTPASS } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js'
import { Account, MovementAccount, Post, Reunion, Song, UserDB, ChordI, ReunionInfo, LiveReunion } from '../interfaces/interfaces';
import { Photo } from '@capacitor/camera'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  users: UserDB[] = []

  constructor(
    private firestore: Firestore,
    private storage: Storage
  ) {
    this.getUsers().subscribe(us => {
      this.users = us
    })
  }

  handleRefresh(e) {
    setTimeout(() => {
      window.location.reload()
      e.target.complete();
    }, 300);
  }

  /* USUARIOS */
  getUsers(): Observable<UserDB[]> {
    const usersRef = collection(this.firestore, 'users')
    return collectionData(usersRef, { idField: 'id' }) as Observable<UserDB[]>
  }

  getUser(id: string): Observable<UserDB> {
    const userDocRef = doc(this.firestore, `users/${id}`)
    return docData(userDocRef, { idField: 'id' }) as Observable<UserDB>
  }

  getUserbyEmail(email: string): Observable<UserDB> {
    const docRef = query(
      collection(this.firestore, 'users'),
      where("email", "==", email))
    return collectionData(docRef, { idField: 'id' }) as Observable<UserDB>
  }

  addUser(user: UserDB) {
    const usersRef = collection(this.firestore, 'users')
    return addDoc(usersRef, user)
  }

  deleteUser(user: UserDB) {
    const userDocRef = doc(this.firestore, `users/${user.id}`)
    return deleteDoc(userDocRef)
  }

  updateUser(user: UserDB, id: string) {
    const userDocRef = collection(this.firestore, 'users')
    return setDoc(doc(userDocRef, id), user)
  }

  async validUserbyEmail({ email }) {
    try {
      let user: UserDB
      this.users.map(u => {
        if (u.email == email) user = u
      })
      return user != null ? true : false
    } catch (e) {
      return false
    }
  }

  async validInitialPassword({ email, password }) {
    try {
      let user: UserDB
      this.users.map(u => {
        if (u.email == email && u.initial_password == password) user = u
      })
      return user != null ? true : false
    } catch (e) {
      return false
    }
  }

  async uploadImageCam(cameraFile: Photo, user: UserDB) {
    const path = `uploads/${user.id}/profile.png`
    const storageRef = ref(this.storage, path)

    try {
      await uploadString(storageRef, cameraFile.base64String, 'base64')
      const imageUrl = await getDownloadURL(storageRef)
      const userDocRef = doc(this.firestore, `users/${user.id}`)
      user.imageUrl = imageUrl
      await setDoc(userDocRef, user)
      return true
    } catch (e) {
      return null
    }
  }

  async uploadImages(image) {
    const path = `uploads/${Date.now() + image.name}`
    const storageRef = ref(this.storage, path)

    try {
      await uploadBytes(storageRef, image, { contentType: `image/${image.name.split('.').pop()}` })
      return await getDownloadURL(storageRef)
    } catch (e) {
      console.log(e)
      return null
    }
  }


  /* POSTS */
  getPosts(limitElements?: number): Observable<Post[]> {
    const postsRef = query(
      collection(this.firestore, 'posts'),
      orderBy("created_at", "desc"),
      /* limit(limitElements) */)
    return collectionData(postsRef, { idField: 'id' }) as Observable<Post[]>
  }

  getPublicPosts(limitElements?: number): Observable<Post[]> {
    const postsRef = query(
      collection(this.firestore, 'posts'),
      where("public", "==", true),
      orderBy("created_at", "desc"),
      /* limit(limitElements) */)
    return collectionData(postsRef, { idField: 'id' }) as Observable<Post[]>
  }

  getPost(id: string): Observable<Post> {
    const postDocRef = doc(this.firestore, `posts/${id}`)
    return docData(postDocRef, { idField: 'id' }) as Observable<Post>
  }

  addPost(post: Post) {
    const postsRef = collection(this.firestore, 'posts')
    return addDoc(postsRef, post)
  }

  deletePost(post: Post) {
    const postDocRef = doc(this.firestore, `posts/${post.id}`)
    return deleteDoc(postDocRef)
  }

  updatePost(post: Post, id: string) {
    const postDocRef = collection(this.firestore, `posts`)
    return setDoc(doc(postDocRef, id), post)
  }

  async uploadImage(image) {
    const path = `uploads/${Date.now() + image.name}`
    const storageRef = ref(this.storage, path)

    try {
      await uploadBytes(storageRef, image, { contentType: `image/${image.name.split('.').pop()}` })
      return await getDownloadURL(storageRef)
    } catch (e) {
      console.log(e)
      return null
    }
  }

  /* CANCIONES */
  getSongs(): Observable<Song[]> {
    const songRef = query(
      collection(this.firestore, 'songs'),
      orderBy("name", "asc")
    )
    /* const songRef = collection(this.firestore, 'songs') */
    return collectionData(songRef, { idField: 'id' }) as Observable<Song[]>
  }

  updateSong(song: Song, id: string) {
    const songDocRef = collection(this.firestore, 'songs')
    return setDoc(doc(songDocRef, id), song)
  }

  getSong(id: string): Observable<Song> {
    const songDocRef = doc(this.firestore, `songs/${id}`)
    return docData(songDocRef, { idField: 'id' }) as Observable<Song>
  }

  addSong(song: Song) {
    const songDocRef = collection(this.firestore, 'songs')
    return addDoc(songDocRef, song)
  }

  /* CATEGORIA CANCIONES */
  /*   getCategories(): Observable<Category[]> {
      const docRef = query(
        collection(this.firestore, 'categories'),
        orderBy("name", "asc")
      )
      return collectionData(docRef, { idField: 'id' }) as Observable<Category[]>
    }
  
    updateCategory(category: Category, id: string) {
      const docRef = collection(this.firestore, 'categories')
      return setDoc(doc(docRef, id), category)
    }
  
    getCategory(id: string): Observable<Category> {
      const docRef = doc(this.firestore, `categories/${id}`)
      return docData(docRef, { idField: 'id' }) as Observable<Category>
    }
  
    addCategory(category: Category) {
      const docRef = collection(this.firestore, 'categories')
      return addDoc(docRef, category)
    } */

  /* ACORDES */
  getChords(): Observable<ChordI[]> {
    const docRef = query(
      collection(this.firestore, 'chords'),
      orderBy("title", "asc")
    )
    return collectionData(docRef, { idField: 'id' }) as Observable<ChordI[]>

  }

  getChord(id: string): Observable<ChordI> {
    const docRef = doc(this.firestore, `chords/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<ChordI>
  }

  updateChord(chord: ChordI) {
    const docRef = collection(this.firestore, 'chords')
    return setDoc(doc(docRef, chord.id), chord)
  }

  addChord(chord: ChordI) {
    const docRef = collection(this.firestore, 'chords')
    return addDoc(docRef, chord)
  }

  /* REUNIONES */
  getReunions(): Observable<Reunion[]> {
    const docRef = collection(this.firestore, 'reunions')
    return collectionData(docRef, { idField: 'id' }) as Observable<Reunion[]>
  }

  getReunion(id: string): Observable<Reunion> {
    const docRef = doc(this.firestore, `reunions/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<Reunion>
  }

  addReunion(reunion: Reunion) {
    const docRef = collection(this.firestore, 'reunions')
    return addDoc(docRef, reunion)
  }

  deleteReunion(reunion: Reunion) {
    const docRef = doc(this.firestore, `reunions/${reunion.id}`)
    return deleteDoc(docRef)
  }

  updateReunion(reunion: Reunion, id: string) {
    const docRef = collection(this.firestore, 'reunions')
    return setDoc(doc(docRef, id), reunion)
  }

  /* LIVE REUNION */
  addLiveReunion(livereunion: LiveReunion) {
    const docRef = collection(this.firestore, 'livereunions')
    return addDoc(docRef, livereunion)
  }

  getLiveReunion(id: string): Observable<Reunion> {
    const docRef = doc(this.firestore, `livereunions/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<LiveReunion>
  }

  updateLiveReunion(livereunion: LiveReunion, id: string) {
    const docRef = collection(this.firestore, 'livereunions')
    return setDoc(doc(docRef, id), livereunion)
  }


  /* INFO REUNIONES */
  getReunionInfos(): Observable<ReunionInfo[]> {
    const docRef = collection(this.firestore, 'reunionsinfo')
    return collectionData(docRef, { idField: 'id' }) as Observable<ReunionInfo[]>
  }

  getReunionInfo(id: string): Observable<ReunionInfo> {
    const docRef = doc(this.firestore, `reunionsinfo/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<ReunionInfo>
  }

  addReunionInfo(reunionInfo: ReunionInfo) {
    const docRef = collection(this.firestore, 'reunionsinfo')
    return addDoc(docRef, reunionInfo)
  }

  deleteReunionInfo(reunionInfo: ReunionInfo) {
    const docRef = doc(this.firestore, `reunionsinfo/${reunionInfo.id}`)
    return deleteDoc(docRef)
  }

  updateReunionInfo(reunionInfo: ReunionInfo) {
    const docRef = collection(this.firestore, 'reunionsinfo')
    return setDoc(doc(docRef, reunionInfo.id), reunionInfo)
  }

  /* CUENTAS */
  getAccounts(): Observable<Account[]> {
    const docRef = collection(this.firestore, 'accounts')
    return collectionData(docRef, { idField: 'id' }) as Observable<Account[]>
  }

  updateAccount(account: Account) {
    const docRef = collection(this.firestore, 'accounts')
    return setDoc(doc(docRef, account.id), account)
  }

  getAccount(id: string): Observable<Account> {
    const docRef = doc(this.firestore, `accounts/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<Account>
  }

  addAccount(account: Account) {
    const docRef = collection(this.firestore, 'accounts')
    return addDoc(docRef, account)
  }

  /* MOV_CUENTAS */
  getMovementAccounts(): Observable<MovementAccount[]> {
    const docRef = collection(this.firestore, 'movementAccounts')
    return collectionData(docRef, { idField: 'id' }) as Observable<MovementAccount[]>
  }

  updateMovementAccount(movementAccount: MovementAccount, id: string) {
    const docRef = collection(this.firestore, 'movementAccounts')
    return setDoc(doc(docRef, id), movementAccount)
  }

  getMovementAccount(id: string): Observable<MovementAccount> {
    const docRef = doc(this.firestore, `movementAccounts/${id}`)
    return docData(docRef, { idField: 'id' }) as Observable<MovementAccount>
  }

  addMovementAccount(account: MovementAccount) {
    const docRef = collection(this.firestore, 'movementAccounts')
    return addDoc(docRef, account)
  }

}
